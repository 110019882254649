import React, { FC } from 'react';
import ThemeProvider from '@pedidosya/web-fenix/theme/ThemeProvider';
import fenixTheme from '@pedidosya/web-fenix/theme';
import FenixFonts from '@pedidosya/web-fenix/theme/FenixFonts';
import { ErrorPage } from '@app/shared/components/ErrorPage';
import { sentryHub } from '@app/shared/utils/sentry';
import type { ErrorBoundaryProps } from './ErrorBoundary.type';

export const ErrorBoundary: FC<ErrorBoundaryProps> = ({ error }) => {
  if (error?.__sentry_captured__) {
    // Allow the hub to re-catch the error if the main sentry instance already caught it
    delete error.__sentry_captured__;
  }

  sentryHub.captureException(error);

  return (
    <ThemeProvider theme={fenixTheme}>
      <FenixFonts />
      <ErrorPage />
    </ThemeProvider>
  );
};
