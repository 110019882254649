/**
 * getItem
 *
 * @description Function for get a value from localstorage
 * @returns string
 */
export const getItem = (name: string, defaultValue?: any): any => {
  try {
    return sessionStorage.getItem(name);
  } catch (e) {
    return defaultValue;
  }
};

/**
 * setItem
 *
 * @description Function for set a value into local storage
 * @param {string} name - Key in the local storage
 * @param {string} value - Value for storage in local
 * @returns {void}
 */
export const setItem = (name: string, value: string | boolean) => {
  try {
    sessionStorage.setItem(name, value.toString());
  } catch (e) {}
};

/**
 * handleItem
 *
 * @description Function for check if existe a value in the local storage, if no existe, set a value by defaul
 * @param {string} name - Key in the local storage
 * @param {string} value - Value for storage in local default
 * @returns {void}
 */
export const handleItem = (name: string, value: string | boolean) => {
  const storage = getItem(name, null);
  if (!storage) {
    setItem(name, value);
  }
};
