/**
 * Constants
 *
 * @description general constants
 */
export const Constants = {
  MOCK_FLAG: 'mockRequest',
  PEYA_PLUS_ICON: 'https://images.deliveryhero.io/image/pedidosya/order-management/peya-plus.png',
  FALLBACK_IMAGE: 'https://images.deliveryhero.io/image/pedidosya/order-management/fallback.jpg',
  BASE_URL_PRODUCTS_IMAGE: 'https://images.deliveryhero.io/image/pedidosya/products',
  BASE_URL_VENDOR_IMAGE: 'https://images.deliveryhero.io/image/pedidosya/restaurants',
  ORIGIN_PATH: 'originPath',
  WEB_VIEWPORT: 1024,
};
