import { DEVICESOS, DEVICES } from '@app/shared/config/device';

export const DeepLink = {
  grocery: {
    ios: 'pedidosya://groceries/productConfiguration?productSku={productId}&vendorId={vendorId}&path={path}',
    android:
      'pedidosya://groceries/productConfiguration?productSku={productId}&vendorId={vendorId}&path={path}',
  },
  food: {
    ios: 'pedidosya://food_product_configuration/{vendorId}/products/{productId}?origin=orderStatus&occasion=DELIVERY&is_joker=false&is_most_ordered=false&menu_section=menu&resolver=SHOPDETAIL',
    android:
      'pedidosya://food_product_configuration/{vendorId}/products/{productId}?origin=orderStatus&occasion=DELIVERY&is_joker=false&is_most_ordered=false&menu_section=menu&resolver=SHOPDETAIL',
  },
  repeatOrder: {
    ios: 'pedidosya://repeatOrder?orderId={orderId}&partnerId={partnerId}',
    android: 'pedidosya://myOrders/{orderId}',
    web: '/',
  },
  otherOrder: {
    ios: 'pedidosya://verticals?bt={vertical}',
    android: 'pedidosya://verticals?bt={vertical}',
    web: '/restaurantes/{city}',
  },
  home: {
    ios: 'pedidosya://launcher',
    android: 'pedidosya://launcher',
    web: '/',
  },
  orderStatus: {
    ios: 'pedidosya://orderStatus?orderId={id}',
    android: 'pedidosya://orderStatus?orderId={id}',
  },
  helpCenter: {
    ios: 'pedidosya://onlinehelp?order_id={id}',
    android: 'pedidosya://onlinehelp?order_id={id}',
    web: '/customer-service?orderId={id}',
  },
  helpCenterChat: {
    ios: 'pedidosya://onlinehelp?order_id={id}&page_id=change_address.chat',
    android: 'pedidosya://onlinehelp?order_id={id}&page_id=change_address.chat',
    web: '/customer-service?orderId={id}&page_id=change_address.chat',
  },
  location: {
    ios: 'pedidosya://location/onboarding-newAddress?origin={origin}',
    android: 'pedidosya://myAddresses',
  },
  location_new: {
    ios: 'pedidosya://location/onboarding-newAddress?origin={origin}',
    android: 'pedidosya://location/onboarding-newAddress?origin={origin}',
  },
  shopList: {
    ios: 'pedidosya://shop_detail/{partnerId}',
    android: 'pedidosya://shop_detail/{partnerId}',
    web: '{url}',
  },
  basket_groceries: {
    ios: 'pedidosya://groceries/cart?cartGuid={basketId}&origin=repeatOrder',
    android: 'pedidosya://groceries/cart?cartGuid={basketId}&origin=repeatOrder',
    web: '/checkout-mfe?basketId={basketId}&type=CART_LEGACY&onExpiredCheckoutRedirectTo=/',
  },
  basket_food: {
    ios: 'pedidosya://food_cart?origin=repeatOrder&partnerId={partnerId}&occasion={deliveryType}&guid={basketId}&resolver=SHOPDETAIL',
    android:
      'pedidosya://food_cart?origin=repeatOrder&partnerId={partnerId}&occasion={deliveryType}&guid={basketId}&resolver=SHOPDETAIL',
    web: '/checkout-mfe?basketId={basketId}&type=CART_LEGACY&onExpiredCheckoutRedirectTo=/',
  },
};

export const ACTIONS = {
  GROCERY: 'grocery',
  FOOD: 'food',
  REPEAT_ORDER: 'repeatOrder',
  OTHER_ORDER: 'otherOrder',
  HOME: 'home',
  ORDER_STATUS: 'orderStatus',
  HELP_CENTER: 'helpCenter',
  HELP_CENTER_CHAT: 'helpCenterChat',
  LOCATION: 'location',
  LOCATION_NEW: 'location_new',
  SHOP_LIST: 'shopList',
  BASKET_GROCERIES: 'basket_groceries',
  BASKET_FOOD: 'basket_food',
};

/**
 * generateDeepLink
 *
 * @description Function for generate deeplink based on os param
 * @param {string} action - Action to generate deep link
 * @param {string} os - OS of device (Ios | AndroidOS)
 * @param {Object} params - Any param to replace in final deeplink
 * @returns {string}
 */
export const generateDeepLink = (action, os, params) => {
  switch (os) {
    case DEVICESOS.ANDROID:
      return replaceAll(DeepLink[action].android, params);
    case DEVICESOS.IOS:
      return replaceAll(DeepLink[action].ios, params);
    case DEVICESOS.WEB:
      return replaceAll(DeepLink[action].web, params);
    default:
      return '';
  }

  function replaceAll(str, params) {
    let finalStr = str;
    for (const [key, value] of Object.entries(params)) {
      finalStr = finalStr.replace(`{${key}}`, value);
    }
    return finalStr;
  }
};

/**
 * generateLink
 *
 * @description Function for genereate link or deeplink in base to platform
 * @param {string} device - Name of device
 * @param {string} deviceOS - Operative System of device
 * @param {Object} params - Object with parameters to build final urls
 */
export const generateLink = (device, deviceOS, action, params) => {
  if (DEVICES.MOBILE === device || DEVICES.WEBVIEW === device) {
    return generateDeepLink(action, deviceOS, params);
  }
  return generateDeepLink(action, DEVICESOS.WEB, params);
};
