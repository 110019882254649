import React from 'react';
import styled from 'styled-components';

export const NavBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${(props) => props.theme.space('space-08')};
`;

export const ActionContainer = styled.div`
  text-align: center;
  margin: 0;
  padding: 0;
  position: fixed;
  z-index: 2;
  bottom: 0;
  left: 0px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px -4px 8px rgba(16, 4, 35, 0.12);
`;

export const ActionInsideContainer = styled.div`
  padding: 16px;
`;

export const AmountContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${(props) => props.theme.space('space-06')};
`;

export const CardSections = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: ${(props) => props.theme.space('space-06')};
  padding: ${(props) => props.theme.space('space-06')};
`;

export const ProductsContainer = styled.div`
  margin-top: ${(props) => props.theme.space('space-20')};
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: ${(props) => props.theme.space('space-01')};
`;

export const ProductContainer = styled.div`
  padding-top: ${(props) => props.theme.space('space-08')};
  padding-bottom: ${(props) => props.theme.space('space-08')};
`;

export const ProductInsideContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: ${(props) => props.theme.space('space-08')};
  justify-content: space-between;
  flex-wrap: nowrap;
  @media (max-width: 320px) {
    flex-wrap: wrap;
  }
`;

export const LeftSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

export const ImageSection = styled.div`
  flex: 0;
  margin-right: ${(props) => props.theme.space('space-06')};
`;

export const AmountSection = styled.div`
  flex: 1;
  display: flex;
  min-width: 100px;
  margin-left: 8px;
  text-align: right;
  flex-direction: row;
  justify-content: flex-end;
  @media (max-width: 320px) {
    text-align: left;
  }
`;

export const DescriptionSection = styled.div`
  flex: 1;
  min-width: 130px;
`;
