import React from 'react';
import isFunction from 'lodash/isFunction';

type WindowIOSInterface = {
  webkit?: {
    messageHandlers?: {
      ListenEventsInterface?: {
        postMessage?: (params: { [key: string]: any }) => void;
      };
    };
  };
};

type WindowAndriodInterface = {
  OrderActionsWebEvents?: {
    sendEvents?: (params: string) => void;
  };
};

type WindowMobile = WindowIOSInterface & WindowAndriodInterface & Window & typeof globalThis;

function useWebViewEvents() {
  const fire = (name: string, params: { [key: string]: any | null } | null) => {
    const wn: WindowMobile = window;
    /**
     * Checking if webview is a device apple and have the interface
     * for send message to window
     * @param ListenEventsInterface is a interface provided by iOS developer
     */
    if (wn.webkit && wn.webkit.messageHandlers) {
      const scopes = wn.webkit.messageHandlers;
      if (
        scopes['ListenEventsInterface'] &&
        isFunction(scopes['ListenEventsInterface']?.postMessage)
      ) {
        scopes['ListenEventsInterface'].postMessage({ event: name, ...params });
      }
      /**
       * Checking if webview is a device android and have the interface
       * for send message to window
       * @param OrderActionsWebEvents is a interface provided by Android Developer
       */
    } else if (
      wn['OrderActionsWebEvents'] &&
      isFunction(wn['OrderActionsWebEvents']['sendEvents'])
    ) {
      wn['OrderActionsWebEvents']['sendEvents'](JSON.stringify({ event: name, ...params }));
    }
  };

  return {
    fire,
  };
}

export { useWebViewEvents };
