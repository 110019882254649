import React, { FC } from 'react';
import { getWindow } from '@app/shared/utils/window';
import { ModulesLoading } from '@app/shared/config/modulesLoading';
import { ModulesPath } from '@app/shared/config/modulesPath';
import { useWindowSize } from '@app/shared/hooks/useWindowSize';
import { Constants } from '@app/shared/config/constants';
import useIsWebView from '@app/shared/hooks/useIsWebView';
import PeyaLoader from '@pedidosya/web-fenix/animations/PeyaLoader';

export const Loading: FC = () => {
  const wn = getWindow();
  const { width } = useWindowSize();

  if (!wn) {
    return <PeyaLoader position="center" />;
  }

  const location = wn.location;
  if (location.pathname.includes(ModulesPath.RECREATION_ORDERS)) {
    if (width >= Constants.WEB_VIEWPORT) {
      const Component = ModulesLoading.RECREATION_ORDERS_WEB_LOADING;
      return (
        <section>
          <Component />
        </section>
      );
    }
    const Component = ModulesLoading.RECREATION_ORDERS_MOBILE_LOADING;
    return (
      <section>
        <Component />
      </section>
    );
  }

  return <PeyaLoader position="center" />;
};
