/**
 * WebSkeletonm
 *
 * @description Component to be used loading state while the page
 * is fully rendered. It will be used only for web version in big resolutions
 */

import React from 'react';
import Skeleton from '@pedidosya/web-fenix/atoms/Skeleton';
import CardContianer from '@pedidosya/web-fenix/molecules/CardContainer';
import { Container } from '@app/shared/components/Container';
import {
  NavBarContainer,
  ProductsContainer,
  ProductContainer,
  ProductInsideContainer,
  LeftSection,
  ImageSection,
  DescriptionSection,
  AmountSection,
  CardSections,
  CardContent,
  ActionContainer,
  AmountContainer,
  LeftSide,
  RightSide,
  Content,
  CenterSide,
  ProfilePhoto,
  MenuOptions,
  ProfileName,
} from './WebSkeleton.style';
import { useWindowSize } from '@app/shared/hooks/useWindowSize';

export const WebSkeleton = () => {
  const { height } = useWindowSize();
  const products = [...Array(4).keys()].map((element, index) => index);

  return (
    <Container data-testid="skeleton_web" pBottom="0">
      <NavBarContainer>
        <Skeleton shape="square" width="shape-size-action-13" height="shape-size-action-08" />
        <Skeleton shape="square" width="shape-size-action-50" height="shape-size-action-05" />
        <div>&nbsp;</div>
      </NavBarContainer>
      <Content height={height}>
        <LeftSide>
          <ProfilePhoto>
            <Skeleton shape="circle" width="shape-size-action-25" height="shape-size-action-25" />
          </ProfilePhoto>
          <ProfileName>
            <div>
              <Skeleton shape="square" width="shape-size-action-13" height="shape-size-action-08" />
            </div>
            <div>
              <Skeleton shape="square" width="shape-size-action-50" height="shape-size-action-05" />
            </div>
          </ProfileName>
          <MenuOptions>
            <div>
              <Skeleton shape="square" width="shape-size-action-13" height="shape-size-action-08" />
              <Skeleton shape="square" width="shape-size-action-50" height="shape-size-action-05" />
            </div>
            <div>
              <Skeleton shape="square" width="shape-size-action-13" height="shape-size-action-08" />
              <Skeleton shape="square" width="shape-size-action-50" height="shape-size-action-05" />
            </div>
            <div>
              <Skeleton shape="square" width="shape-size-action-13" height="shape-size-action-08" />
              <Skeleton shape="square" width="shape-size-action-50" height="shape-size-action-05" />
            </div>
          </MenuOptions>
        </LeftSide>
        <CenterSide>
          <ProductsContainer>
            <Skeleton shape="square" width="shape-size-action-20" height="shape-size-action-04" />
            {products.map((element) => {
              return (
                <ProductContainer key={element}>
                  <ProductInsideContainer>
                    <LeftSection>
                      <ImageSection>
                        <Skeleton
                          shape="square"
                          width="shape-size-action-10"
                          height="shape-size-action-10"
                        />
                      </ImageSection>
                      <DescriptionSection>
                        <Skeleton
                          shape="square"
                          width="shape-size-action-40"
                          height="shape-size-action-05"
                        />
                      </DescriptionSection>
                    </LeftSection>
                    <AmountSection>
                      <Skeleton
                        shape="square"
                        width="shape-size-action-10"
                        height="shape-size-action-12"
                      />
                    </AmountSection>
                  </ProductInsideContainer>
                </ProductContainer>
              );
            })}
          </ProductsContainer>
        </CenterSide>
        <RightSide>
          <div>
            <CardContianer withShadow={false}>
              <CardSections>
                <Skeleton
                  shape="square"
                  width="shape-size-action-10"
                  height="shape-size-action-10"
                />
                <CardContent>
                  <Skeleton
                    shape="square"
                    width="shape-size-action-40"
                    height="shape-size-action-05"
                  />
                  <Skeleton
                    shape="square"
                    width="shape-size-action-30"
                    height="shape-size-action-07"
                  />
                  <Skeleton
                    shape="square"
                    width="shape-size-action-20"
                    height="shape-size-action-04"
                  />
                </CardContent>
              </CardSections>
            </CardContianer>
          </div>
          <ActionContainer>
            <AmountContainer>
              <Skeleton shape="square" width="shape-size-action-16" height="shape-size-action-04" />
              <Skeleton shape="square" width="shape-size-action-16" height="shape-size-action-04" />
            </AmountContainer>
            <Skeleton shape="square" height="shape-size-action-12" />
          </ActionContainer>
        </RightSide>
      </Content>
    </Container>
  );
};
