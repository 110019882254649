import { ExternalUrls } from '@app/shared/config/externalUrls';
import { ExternalHttpClient } from '@app/shared/utils/http';
import { Constants } from '@app/shared/config/constants';

export const ExternalRepository = {
  /**
   * getRedirectLogin
   *
   * @description Method for get url redirect when user is no logged
   */
  getRedirectLogin: async () => {
    const result = await ExternalHttpClient.getInstance().get(`${ExternalUrls.LOGIN}`);
    return result?.data;
  },
};
