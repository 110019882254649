import React from 'react';
import styled from 'styled-components';
import Typography from '@pedidosya/web-fenix/atoms/Typography';

export const Content = styled.div<{ height?: number }>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: ${(props) => (props.height ? `${props.height}px` : 'auto')};
`;

export const Title = styled(Typography)`
  margin-top: ${(props) => props.theme.space('space-08')};
  width: 130px;
  text-align: center;
`;

export const ActionContainer = styled.div`
  text-align: center;
  margin: 0;
  padding: 0;
  position: fixed;
  z-index: 2;
  bottom: 0;
  left: 0px;
  width: 100%;
  background-color: #fff;
  outline: 0;
`;

export const ActionInsideContainer = styled.div`
  padding: 16px;
  outline: 0;
`;

export const NavBarContainer = styled.div`
  text-align: right;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;
