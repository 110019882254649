/**
 * getWindow
 *
 * @description Get window instance
 * @returns {window}
 */
export const getWindow = () => {
  if (typeof window !== 'undefined') {
    return window;
  }
  return null;
};

/**
 * getWindowY
 *
 * @description Function for get window scroll y
 * @param {number} defaultValue - Default value if can not get information about scroll of window
 * @returns {number}
 */
export const getWindowY = (defaultValue: number) => {
  const w = getWindow();
  if (w) {
    if (w.pageYOffset !== null && w.pageYOffset !== undefined) {
      return w.pageYOffset;
    } else if (w.scrollY !== null && w.scrollY !== undefined) {
      return w.scrollY;
    }
  }

  return defaultValue || 0;
};

/**
 * getWindowHeight
 *
 * @description Function to get the window height
 * @returns {number}
 */
export const getWindowHeight = () => {
  const w = getWindow();
  if (w) {
    return w.innerHeight;
  }

  return 0;
};
