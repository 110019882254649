import React from 'react';
import styled from 'styled-components';
import { ContainerProps } from './Container.type';

export const Container = styled.div<ContainerProps>`
  padding-top: ${(props) => (props.pTop ? `${props.pTop}px` : '16px')};
  padding-bottom: ${(props) => (props.pBottom ? `${props.pBottom}px` : '30px')};
  padding-left: ${(props) => (props.pLeft ? `${props.pLeft}px` : '16px')};
  padding-right: ${(props) => (props.pRight ? `${props.pRight}px` : '16px')};
  height: ${(props) => (props.height ? `${props.height}px` : 'auto')};
  position: relative;
`;
