import React from 'react';
import PropTypes from 'prop-types';
import fenixTheme from '@pedidosya/web-fenix/theme';
import ThemeProvider from '@pedidosya/web-fenix/theme/ThemeProvider';
import FenixFonts from '@pedidosya/web-fenix/theme/FenixFonts';
import { SentryProvider } from '@app/shared/contexts/SentryProvider';
import { LinkProvider } from '@app/shared/contexts/LinkProvider';
import { createIntlProvider, getMessages } from '@app/shared/contexts/IntlProvider';
import { TrackerProvider } from '@app/shared/contexts/TrackerProvider';
import {
  usePublicEnv,
  PublicEnvProvider,
  publicEnvShape,
  getPublicEnv,
} from '@app/shared/contexts/PublicEnv';
import { RouterApp } from '@app/shared/components/RouterApp';
import { BrowserRouter as Router } from 'react-router-dom';
import { StaticRouter } from 'react-router-dom/server';
import { LocalStyle } from '@app/shared/components/LocalStyle';
import { AuthProvider } from '@app/shared/contexts/AuthProvider';
import { GlobalError } from '@app/shared/components/GlobalError';

const IntlProvider = (props) => {
  const { locale } = usePublicEnv();
  return createIntlProvider(locale, getMessages)(props);
};

const Root = ({ context, messages, name, isServer, location, trackerConfig }) => {
  const RouterBase = isServer ? StaticRouter : Router;
  return (
    <GlobalError>
      <PublicEnvProvider context={context} getPublicEnv={getPublicEnv}>
        <SentryProvider standalone={context?.standalone}>
          <FenixFonts />
          <LocalStyle />
          <ThemeProvider theme={fenixTheme}>
            <IntlProvider initialMessages={messages}>
              <LinkProvider>
                <AuthProvider>
                  <TrackerProvider trackerConfig={trackerConfig}>
                    <RouterBase location={location}>
                      <RouterApp />
                    </RouterBase>
                  </TrackerProvider>
                </AuthProvider>
              </LinkProvider>
            </IntlProvider>
          </ThemeProvider>
        </SentryProvider>
      </PublicEnvProvider>
    </GlobalError>
  );
};

const ssrProps = typeof window !== 'undefined' ? window.__INITIAL_DATA__ : {};
Root.defaultProps = { ...ssrProps };

Root.propTypes = {
  context: PropTypes.shape(publicEnvShape),
  messages: PropTypes.shape({}),
  name: PropTypes.string.isRequired,
  isServer: PropTypes.bool,
  location: PropTypes.string,
  trackerConfig: PropTypes.object,
};

export default Root;
