import React, { createContext, useContext } from 'react';
import { LinkProviderResult } from './provider.type';

export const LinkContext = createContext<LinkProviderResult>({} as LinkProviderResult);

/**
 * useLinkContext
 *
 * @description Context for create link o deeplink base on plataform
 * @returns { useLinkContext }
 */
export const useLinkContext = () => {
  return useContext(LinkContext);
};
