import React, { Component, ReactNode } from 'react';
import ThemeProvider from '@pedidosya/web-fenix/theme/ThemeProvider';
import fenixTheme from '@pedidosya/web-fenix/theme';
import FenixFonts from '@pedidosya/web-fenix/theme/FenixFonts';
import Error from '@pedidosya/web-fenix/pages/Error';
import { getWindow } from '@app/shared/utils/window';
import { sentryHub } from '@app/shared/utils/sentry';

type Props = {
  children?: ReactNode;
};

type State = {
  error: any;
  hasError: boolean;
};

export class GlobalError extends Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = { error: null, hasError: false };
  }

  componentDidCatch(error: any) {
    this.setState({ error, hasError: true });
    if (error?.__sentry_captured__) {
      // Allow the hub to re-catch the error if the main sentry instance already caught it
      delete error.__sentry_captured__;
    }
    if (sentryHub) {
      sentryHub.captureException(error);
    }
  }

  public reload() {
    const wn = getWindow();
    if (wn) {
      wn.location.reload();
    }
  }

  render() {
    const { error, hasError } = this.state;
    if (hasError) {
      return (
        <ThemeProvider theme={fenixTheme}>
          <FenixFonts />
          <Error onClick={this.reload} />
        </ThemeProvider>
      );
    } else {
      return <>{this.props.children}</>;
    }
  }
}
