import React from 'react';
import styled from 'styled-components';

export const NavBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${(props) => props.theme.space('space-08')};
`;

export const ActionContainer = styled.div`
  padding: 16px 0px 16px 16px;
`;

export const AmountContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${(props) => props.theme.space('space-06')};
`;

export const CardSections = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  gap: ${(props) => props.theme.space('space-06')};
  padding: ${(props) => props.theme.space('space-06')};
`;

export const ProductsContainer = styled.div`
  margin-top: ${(props) => props.theme.space('space-10')};
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: ${(props) => props.theme.space('space-01')};
`;

export const ProductContainer = styled.div`
  padding-top: ${(props) => props.theme.space('space-08')};
  padding-bottom: ${(props) => props.theme.space('space-08')};
`;

export const ProductInsideContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  @media (max-width: 320px) {
    flex-wrap: wrap;
  }
`;

export const LeftSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

export const ImageSection = styled.div`
  flex: 0;
  margin-right: ${(props) => props.theme.space('space-06')};
`;

export const AmountSection = styled.div`
  flex: 1;
  display: flex;
  min-width: 100px;
  margin-left: 8px;
  text-align: right;
  flex-direction: row;
  justify-content: flex-end;
  @media (max-width: 320px) {
    text-align: left;
  }
`;

export const DescriptionSection = styled.div`
  flex: 1;
  min-width: 130px;
`;

export const LeftSide = styled.aside`
  width: 30%;
  padding-top: ${(props) => props.theme.space('space-08')};
  height: 100%;
`;

export const CenterSide = styled.aside`
  width: 40%;
  height: 100%;
`;

export const RightSide = styled.aside`
  width: 30%;
  position: relative;
  height: 100%;
  &:after {
    content: ' ';
    display: block;
    height: 100%;
    position: absolute;
    width: 1px;
    top: 0px;
    left: 0px;
    background-color: #ccc;
  }
  & > div {
    padding-left: 16px;
    position: sticky;
    top: 50px;
    left: 0px;
  }
`;

export const Content = styled.div<{ height?: number }>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: ${(props) => props.theme.space('space-10')};
  height: ${(props) => (props.height ? `${props.height - 68}px` : 'auto')};
`;

export const ProfilePhoto = styled.div`
  display: block;
  width: 100%;
  text-align: center;
  & > * {
    display: inline-block;
  }
`;

export const ProfileName = styled.div`
  width: 100%;
  text-align: center;
  margin-top: ${(props) => props.theme.space('space-10')};
  margin-bottom: ${(props) => props.theme.space('space-10')};
`;

export const MenuOptions = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: flex-start;
  gap: ${(props) => props.theme.space('space-10')};
  margin-bottom: ${(props) => props.theme.space('space-10')};
  & > div {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    gap: ${(props) => props.theme.space('space-10')};
  }
`;
