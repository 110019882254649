import React, { FC } from 'react';
import { LinkContext } from './context';
import { usePublicEnv } from '@app/shared/contexts/PublicEnv';
import { generateLink } from '@app/shared/utils/deeplink';
import { useWebViewEvents } from '@app/shared/hooks/useWebViewEvents';
import { LinkProviderProps } from './provider.type';

/**
 * LinkProvider
 *
 * @description Provider for generate link base on device and deviceOS will generate a link or deeplink
 * @param {Object} Props - React Props
 * @returns React.Node
 */
const LinkProvider: FC<LinkProviderProps> = ({ children }) => {
  const { device, deviceOS } = usePublicEnv();
  const { fire } = useWebViewEvents();

  const contextValue = {
    /**
     * redirect
     *
     * @description Function for make a redirect based on device
     * @param {ACTIONS} action  - Action to generate link
     * @param {Object} params - Params required to build de url
     */
    redirect: (action: string, params: { [key: string]: any } = {}) => {
      window.location.href = generateLink(device, deviceOS, action, params || {});
    },
    /**
     * emitEvent
     * @param {*name} name - Name of event to send to webview
     * @param {*} params - Extra params to send to webview
     */
    emitEvent: (name: string, params: { [key: string]: any } = {}) => {
      fire(name, params);
    },
  };

  return <LinkContext.Provider value={contextValue}>{children}</LinkContext.Provider>;
};

export { LinkProvider };
