import {
  BrowserClient,
  Exception,
  Hub,
  BrowserTracing,
  defaultStackParser,
  makeFetchTransport,
  Scope,
  reactRouterV6Instrumentation,
  getCurrentHub,
} from '@sentry/react';
import { useEffect } from 'react';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import type { Init, User } from './types';
const { PROD_ENVIRONMENT } = require('@appscore/web-constants/environments');

let customHub: Hub;

const sentryHub = {
  init: (payload: Init) => {
    if (customHub) {
      return;
    }
    const isLive = payload.environment === PROD_ENVIRONMENT;

    const client = new BrowserClient({
      dsn: SENTRY_DSN,
      environment: payload.environment,
      integrations: [
        new BrowserTracing({
          _metricOptions: {
            _reportAllChanges: true,
          },
          routingInstrumentation: reactRouterV6Instrumentation(
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
          ),
        }),
      ],
      release: SENTRY_RELEASE,
      tracesSampleRate: isLive ? 0.1 : 1,
      sampleRate: isLive ? 0.1 : 1,
      enabled: true,
      stackParser: defaultStackParser,
      transport: makeFetchTransport,
      autoSessionTracking: true,
    });

    if (payload.standalone) {
      getCurrentHub().bindClient(client);
      customHub = new Hub(client);
    } else {
      customHub = new Hub(client);
      getCurrentHub().bindClient(client);
    }

    return customHub;
  },
  captureMessage: (message: string) => {
    if (!customHub) return null;
    customHub.run((hub) => hub.captureMessage(message));
  },
  captureException: (ex: Exception) => {
    if (!customHub) return null;
    customHub.run((hub) => hub.captureException(ex));
  },
  setTag: (key: string, value: string) => {
    if (!customHub) {
      throw new Error('Cannot set tags before hub initialization');
    }
    customHub.setTag(key, value);
  },
  setUser: (user: User) => {
    if (!customHub) {
      throw new Error('Cannot set user before hub initialization');
    }
    customHub.setUser(user);
  },
  withScope: (cb: (scope: Scope) => void) => {
    if (!customHub) {
      throw new Error('Cannot set user before hub initialization');
    }
    customHub.run((hub) => {
      hub.withScope(cb);
    });
  },
  startSession: () => {
    if (!customHub) return null;
    customHub.run((hub) => {
      hub.withScope(() => {
        hub.startSession();
        hub.captureSession();
      });
    });
  },
};

Object.freeze(sentryHub);

export { sentryHub, customHub };
