import React, { FC, useEffect, useState } from 'react';
import { Container } from '@app/shared/components/Container';
import {
  ActionContainer,
  ActionInsideContainer,
  Content,
  Title,
  NavBarContainer,
} from './ErrorPage.style';
import { useWindowSize } from '@app/shared/hooks/useWindowSize';
import { getWindow } from '@app/shared/utils/window';
import { useWebViewEvents } from '@app/shared/hooks/useWebViewEvents';
import { WebViewEvents } from '@app/shared/config/webViewEvents';
import useIsWebView from '@app/shared/hooks/useIsWebView';
import NavBar from '@pedidosya/web-fenix/organisms/Navbar';
import Image from '@pedidosya/web-fenix/foundations/Image';
import Button from '@pedidosya/web-fenix/atoms/Button';
import Close from '@pedidosya/web-fenix/icons/Close';
import Error from '@app/assets/images/error.svg';

export const ErrorPage: FC = () => {
  const { height } = useWindowSize();
  const [minHeight, setMinHeight] = useState<number>(0);
  const { fire } = useWebViewEvents();
  const isWebView = useIsWebView();
  const bottomSize = 72;
  const navbarSize = 48;
  /**
   * To allow calc when render is in server side
   */
  useEffect(() => {
    setMinHeight(Math.max(0, height - bottomSize - navbarSize));
  }, [height]);

  /**
   * onClose
   *
   * @description Function to close de error page, if it is webview
   * fire a event otherwise if it is a web just call to history back
   */
  const onClose = () => {
    const wn: (Window & typeof globalThis) | null = getWindow();
    if (wn) {
      if (isWebView) {
        fire(WebViewEvents.CLOSE, {});
      }

      wn.history.back();
    }
  };

  const onRetry = () => {
    const wn: (Window & typeof globalThis) | null = getWindow();
    if (wn) {
      wn.location.reload();
    }
  };

  return (
    <Container>
      <NavBar>
        <NavBarContainer>
          <Close onClick={onClose} />
        </NavBarContainer>
      </NavBar>
      <Content height={minHeight}>
        <Image src={Error} alt="Error image" width="64px" height="111px" />
        <Title component="h3" token="font-headline-highcontrast-small" color="text-color-primary">
          Lo sentimos, algo sali&oacute; mal
        </Title>
      </Content>
      <ActionContainer>
        <ActionInsideContainer>
          <Button
            type="button"
            fullWidth={true}
            label="Reintentar"
            hierarchy="primary"
            onClick={onRetry}
          />
        </ActionInsideContainer>
      </ActionContainer>
    </Container>
  );
};
