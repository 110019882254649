import React, { memo, useMemo, FC, useEffect, useState, useCallback } from 'react';
import { TrackerContext } from './context';
import { TrackerProviderProps, Tracker } from './provider.type';
import { initialize as initializeTracker, track } from '@pedidosya/web-native-bridges/tracker';
import isEmpty from 'lodash/isEmpty';

const TrackerProvider: FC<TrackerProviderProps> = memo(
  ({ children, tracker = {}, trackerConfig = {} }) => {
    const [trackerInstance, setTracker] = useState<Tracker>(tracker);

    const loadTracker = useCallback(async () => {
      try {
        (await initializeTracker(trackerConfig)) as Tracker;
        setTracker({ track });
      } catch (e) {
        return null;
      }
    }, [trackerConfig]);

    useEffect(() => {
      if (!isEmpty(trackerInstance)) {
        return;
      }

      loadTracker();
    }, [trackerInstance, loadTracker]);

    const value = useMemo(
      () => ({
        gtmTrack: trackerInstance?.track,
      }),
      [trackerInstance],
    );

    return <TrackerContext.Provider value={value}>{children}</TrackerContext.Provider>;
  },
);

export { TrackerProvider };
