import React from 'react';
import Skeleton from '@pedidosya/web-fenix/atoms/Skeleton';
import CardContianer from '@pedidosya/web-fenix/molecules/CardContainer';
import {
  ActionContainer,
  ActionInsideContainer,
  AmountContainer,
  NavBarContainer,
  CardSections,
  CardContent,
  ProductsContainer,
  ProductContainer,
  ProductInsideContainer,
  LeftSection,
  ImageSection,
  AmountSection,
  DescriptionSection,
} from './MainSkeleton.style';
import { Container } from '@app/shared/components/Container';

export const MainSekelton = () => {
  const products = [...Array(2).keys()].map((element, index) => index);
  return (
    <Container data-testid="skeleton">
      <NavBarContainer>
        <Skeleton shape="square" width="shape-size-action-13" height="shape-size-action-08" />
        <Skeleton shape="square" width="shape-size-action-50" height="shape-size-action-05" />
        <div>&nbsp;</div>
      </NavBarContainer>
      <div>
        <CardContianer withShadow={false}>
          <CardSections>
            <Skeleton shape="square" width="shape-size-action-10" height="shape-size-action-10" />
            <CardContent>
              <Skeleton shape="square" width="shape-size-action-40" height="shape-size-action-05" />
              <Skeleton shape="square" width="shape-size-action-30" height="shape-size-action-07" />
              <Skeleton shape="square" width="shape-size-action-20" height="shape-size-action-04" />
            </CardContent>
          </CardSections>
        </CardContianer>
      </div>
      <ProductsContainer>
        <Skeleton shape="square" width="shape-size-action-20" height="shape-size-action-04" />
        {products.map((element) => {
          return (
            <ProductContainer key={element}>
              <ProductInsideContainer>
                <LeftSection>
                  <ImageSection>
                    <Skeleton
                      shape="square"
                      width="shape-size-action-10"
                      height="shape-size-action-10"
                    />
                  </ImageSection>
                  <DescriptionSection>
                    <Skeleton
                      shape="square"
                      width="shape-size-action-40"
                      height="shape-size-action-05"
                    />
                  </DescriptionSection>
                </LeftSection>
                <AmountSection>
                  <Skeleton
                    shape="square"
                    width="shape-size-action-10"
                    height="shape-size-action-12"
                  />
                </AmountSection>
              </ProductInsideContainer>
            </ProductContainer>
          );
        })}
      </ProductsContainer>
      <ActionContainer>
        <ActionInsideContainer>
          <AmountContainer>
            <Skeleton shape="square" width="shape-size-action-16" height="shape-size-action-04" />
            <Skeleton shape="square" width="shape-size-action-16" height="shape-size-action-04" />
          </AmountContainer>
          <Skeleton shape="square" height="shape-size-action-12" />
        </ActionInsideContainer>
      </ActionContainer>
    </Container>
  );
};
