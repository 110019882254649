/**
 * AuthProvider
 *
 * @descrition Provider for check if a user is logged, otherwise
 * will redirect to login page (only applied for web version)
 */

import React, { useEffect, useState, useCallback, FC } from 'react';
import { usePublicEnv } from '@app/shared/contexts/PublicEnv';
import { ExternalRepository } from '@app/shared/repositories/external';
import { Constants } from '@app/shared/config/constants';
import { ProviderProps } from './provider.type';
import { getWindow } from '@app/shared/utils/window';
import { Loading } from '@app/shared/components/Loading';
import useIsWebView from '@app/shared/hooks/useIsWebView';

export const AuthProvider: FC<ProviderProps> = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const env = usePublicEnv();
  const isWebView = useIsWebView();

  const urlRedirect = useCallback(async () => {
    try {
      const wn = getWindow();
      const result = await ExternalRepository.getRedirectLogin();
      window.location.href = `${result?.redirectUrl}&${
        Constants.ORIGIN_PATH
      }=${wn?.location?.pathname.substring(1)}${wn?.location?.search}`;
    } catch (e) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (env && !isWebView) {
      if (!env?.user?.userId) {
        urlRedirect();
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [env?.user?.userId, isWebView, urlRedirect]);

  if (loading && !isWebView) {
    return <Loading />;
  }

  return <>{children}</>;
};
