import React, { useContext, useEffect, useState, createContext } from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary } from '@app/shared/components/ErrorBoundary';
import { handleItem } from '@app/shared/utils/storage';
import { Constants } from '@app/shared/config/constants';
import { HttpClient } from '@app/shared/utils/http';
import { Urls } from '@app/shared/config/urls';
import { Loading } from '@app/shared/components/Loading';
import ThemeProvider from '@pedidosya/web-fenix/theme/ThemeProvider';
import fenixTheme from '@pedidosya/web-fenix/theme';

const PublicEnvContext = createContext();

export const getPublicEnv = async () => {
  const http = HttpClient.getInstance();

  const { data } = await http.get(Urls.ENV);
  handleItem(Constants.MOCK_FLAG, data.mock);
  return data;
};

export const usePublicEnv = () => {
  return useContext(PublicEnvContext);
};

export const PublicEnvProvider = ({ children, context, getPublicEnv }) => {
  const [publicEnv, setPublicEnv] = useState(context);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (publicEnv) {
      handleItem(Constants.MOCK_FLAG, publicEnv.mock);
      return;
    }
    getPublicEnv().then(setPublicEnv).catch(setError);
  }, [getPublicEnv, publicEnv]);

  if (error) return <ErrorBoundary error={error} />;

  if (!publicEnv)
    return (
      <ThemeProvider thene={fenixTheme}>
        <Loading />
      </ThemeProvider>
    );

  return <PublicEnvContext.Provider value={publicEnv}>{children}</PublicEnvContext.Provider>;
};

export const publicEnvShape = {
  locale: PropTypes.string.isRequired,
  device: PropTypes.string.isRequired,
  environment: PropTypes.string.isRequired,
  country: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  mock: PropTypes.bool,
};
